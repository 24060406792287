body {
    background: #2b353d;
}

.pushable>.pusher {
    background: #2b353d;
}

.content {
    background: #2b353d;
    width: 100%;
    height: calc(100vh - 58px);
    color: white;
    text-align: center;

    display: table;
    position: absolute;
}

.centre {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
