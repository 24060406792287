.login {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  
    background: #2b353d;
    background: radial-gradient(ellipse at center, #1d535f 0, #2b353d 100%);
  }
  
  .form {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  
    user-select: none;
    overflow: hidden;
  
    color: #fff;
  }
  
  .svg-inline--fa {
    font-size: 20px;
    position: relative;
    top: 6.5px;
  }

  .fa-empty {
    color: #fff;
  }
  
  .fa-invalid, .fa-valid-0,  .fa-valid-1  {
    color: rgb(187, 28, 28);
  }

  .fa-valid-2  {
    color: rgb(176, 179, 17);
  }

  .fa-valid-3  {
    color: rgb(130, 179, 17);
  }

  .fa-valid, .fa-valid-4  {
    color: rgb(17, 179, 30);
  }

  label {
    position: relative;
    width: 240px;
    margin: 14px auto;
    display: block;

    height: 33px;
    text-align: left;
    transition: margin 0.2s ease, transform 0.4s ease, opacity 0.4s linear,
      height 0.2s ease;
    z-index: 1;
  }
  label > span {
    position: absolute;
  
    top: 0px;
    left: 0px;
  
    width: 30px;
    height: 33px;
  
    text-align: center;
    vertical-align: middle;
  
    z-index: 2;
    border-radius: 5px 0 0 5px;
    background: #2b353d;
  
    font-size: 13px;
    opacity: 0.5;
    /*transition: transform 0.2s ease;*/
  
    margin: 0;
    padding: 0;
    border: 0;
  
    color: #fff;
  }
  
  input {
    background: #338b9e;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 33px;
    line-height: 33px;
    box-sizing: border-box;
    padding: 0 5px 0 35px;
    font-size: 13px;
    color: #fff;
    transition: background-color 0.2s linear;
    margin: 0;
  }
  
  .icon span {
    COLOR: rgb(156, 123, 17);
    font-size: 700%;
  }
  
  .btn-container {
    width: 240px;
    height: 46px;
    margin: auto;
    vertical-align: middle;
    text-align: center;
  }
  
  .left {
    float: left;
    width: 100px;
  }
  
  .right {
    float: right;
    width: 100px;
  }
  
  .btn {

    box-shadow: 0px 1px 0px 0px #b39332;
    background-color:#eae0c2;
    border-radius:15px;
    border:2px solid #c9b380;
    display:inline-block;
    cursor:pointer;
    color:#232e00;
    font-family:Arial;
    font-size:14px;
    font-weight:bold;
    padding:12px 16px;
    text-decoration:none;
   
  }
  .btn:hover {
      background-color:#ccc2a6;
  }
  .btn:active {
    position: relative;
    top:1px;
  }
  
  .btn[disabled]:hover, .btn[disabled] {
    background:  #83817a8e;
    color:#e4e9d780;
    box-shadow: 0px 1px 0px 0px #9e99896b;
    border:2px solid #c9b38033;
  }

  .btn:hover {
    background: #eae0c2;
  }

  .btn:focus {
    background-color: #eae0c2;
  }

  .text-btn {
    position: relative;
    background: none;
    color: white;
    height: 44px;
    border: 0;
    text-decoration: underline;
  }

  .hide {
    display: None;
  }